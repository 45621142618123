/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
// import Slider from "react-slick";
const Kopie0 = `${process.env.REACT_APP_S3_BUCKET}/Images/Kopie_0.svg`;
const Kopie1 = `${process.env.REACT_APP_S3_BUCKET}/Images/kopie_1.svg`;
// const Kopie2 = `${process.env.REACT_APP_S3_BUCKET}/Images/kopie_2.svg`;
const Solana = `${process.env.REACT_APP_S3_BUCKET}/Images/solana.png`;
const Kopie3 = `${process.env.REACT_APP_S3_BUCKET}/Images/kopie_3.svg`;
const Kopie4 = `${process.env.REACT_APP_S3_BUCKET}/Images/kopielogo-4.svg`;
const Kopie5 = `${process.env.REACT_APP_S3_BUCKET}/Images/Kopie_5.png`;
const CatLogo = `${process.env.REACT_APP_S3_BUCKET}/Images/cat-logo.jpg`;
const ChainLogo = `${process.env.REACT_APP_S3_BUCKET}/Images/chain-play-logo.svg`;
const KickLogo = `${process.env.REACT_APP_S3_BUCKET}/Images/kick-logo.png`;

const partners = [
  {
    logo: Kopie0,
    redirectLink: "#",
  },
  {
    logo: Kopie1,
    redirectLink: "#",
  },
  {
    logo: Kopie3,
    redirectLink: "#",
  },
  {
    logo: ChainLogo,
    redirectLink: "#",
  },
  {
    logo: KickLogo,
    redirectLink: "https://kick.com/category/guild-of-heroes/clips",
    enableRedirect: true,
  },
  {
    logo: Solana,
    redirectLink: "#",
  },
  {
    logo: Kopie5,
    redirectLink: "#",
  },
  {
    logo: Kopie4,
    redirectLink: "#",
  },
  {
    logo: CatLogo,
    redirectLink: "#",
  },
];

const OurAlliances = () => {
  // const settings = {
  //   infinite: true,
  //   speed: 5000,
  //   autoplaySpeed: 5000,
  //   centerPadding: "60px",
  //   slidesToShow: 5,
  //   slidesToScroll: 1,
  //   autoplay: true,
  //   arrows: false,
  //   cssEase: "linear",
  //   pauseOnHover: false,
  //   responsive: [
  //     {
  //       breakpoint: 1025,
  //       settings: {
  //         slidesToShow: 4,
  //         slidesToScroll: 1,
  //       },
  //     },
  //     {
  //       breakpoint: 640,
  //       settings: {
  //         slidesToShow: 3,
  //         slidesToScroll: 3,
  //       },
  //     },
  //     {
  //       breakpoint: 480,
  //       settings: {
  //         slidesToShow: 2,
  //         slidesToScroll: 2,
  //       },
  //     },
  //   ],
  // };
  return (
    <section className="ouralliances text-center">
      <div className="container">
        <h2 className="h2head">Our Alliances</h2>
      </div>
      <div className="imglist ">
        {/* <Slider {...settings}> */}
        {partners.map((item, index) => {
          return (
            <img
              src={item.logo}
              alt={`partner_${index}`}
              key={index}
              onClick={() => {
                item.enableRedirect && window.open(item.redirectLink, "_blank");
              }}
            />
          );
        })}
        {/* </Slider> */}
      </div>
    </section>
  );
};

export default OurAlliances;
