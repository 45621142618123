import { useEffect, useState } from "react";
import Button from "../../../Components/Dbutton";
import DModel from "../../../Components/Dmodel";
import { airdropData } from "../../../Constants/list";
import TextInput from "../../../Components/Dinput";
import { useWeb3Modal } from "@web3modal/wagmi/react";
import { useAccount, useDisconnect } from "wagmi";
import {
  useGetUserByIdMutation,
  useLoginWithWalletMutation,
} from "../../../services/user";
import { toast } from "react-toastify";
import { setToken } from "../../../store/slices/authSlice";
import { useDispatch, useSelector } from "react-redux";
import { generateRandomUsername } from "../../../utils/helper";
const DWallet = `${process.env.REACT_APP_S3_BUCKET}/Images/wallet.svg`;

const DairdropModel = ({ id, isActive, onClose }) => {
  const [isData, setIsData] = useState();
  const [loginWithWallet, { isLoading: isConneting }] =
    useLoginWithWalletMutation();
  const [getUserById, { isLoading }] = useGetUserByIdMutation();
  const { user } = useSelector((state) => state.auth);
  const [isLoggedIn] = useState(user ? true : false);
  const dispatch = useDispatch();

  const { address, connector } = useAccount();
  const { open } = useWeb3Modal();
  const { disconnect } = useDisconnect();

  useEffect(() => {
    const loginUser = async () => {
      const body = {
        walletId: address,
        userName: generateRandomUsername(),
      };

      try {
        const response = await loginWithWallet(body);
        const { playFabData, token } = response?.data?.data;
        const entityToken = playFabData?.data?.EntityToken?.EntityToken;
        const playFabId = playFabData?.data?.PlayFabId;
        const sessionTicket = playFabData?.data?.SessionTicket;
        const jwtToken = token;

        localStorage.setItem("token", sessionTicket);
        localStorage.setItem("userId", playFabId);
        localStorage.setItem("entityToken", entityToken);
        localStorage.setItem("jwtToken", jwtToken);

        window.PlayFab._internalSettings.entityToken = entityToken;
        window.PlayFab._internalSettings.sessionTicket = sessionTicket;
        window.PlayFab.settings.titleId =
          process.env.REACT_APP_PLAYFAB_TITLE_ID;
        window.PlayFab.settings.developerSecretKey =
          process.env.REACT_APP_PLAYFAB_SECRET_KEY;

        dispatch(setToken({ token: sessionTicket, jwtToken }));
        await getUserById(playFabId);
        onClose();
      } catch (error) {
        toast.error("Failed to login with wallet");
      }
    };

    if (connector && address && !isLoggedIn) {
      loginUser();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [address, connector]);

  useEffect(() => {
    const currentItem = airdropData.find((item) => item.id === id);
    setIsData(currentItem);
  }, [id]);

  return (
    <>
      <DModel
        onClose={() => {
          onClose();
          setIsData();
        }}
        isActive={isActive}
        extraClasses="p-0"
      >
        <div
          className="d-icon"
          style={{
            background: "#fff",
            boxShadow: `0 0 50px #fff`,
          }}
        >
          <img src={DWallet} alt={isData?.title} />
        </div>
        <div className="d-model-text">
          {!address ? (
            <>
              <h3>{isData?.title}</h3>
              <p>
                Connect your cryptocurrency wallet. If you don’t have one, you
                can create an EVM-compatible wallet. Guild of Heroes will be on
                Avalanche, so make sure your wallet supports it.{" "}
              </p>
              <Button
                variant="primary-contained"
                text="Connect your wallet"
                extraClasses="w-full"
                onClick={() => open()}
              />
            </>
          ) : isLoading || isConneting ? (
            <div
              style={{
                height: "200px",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              Connecting your wallet please wait...
              <div className="loader" style={{ marginTop: "20px" }}></div>
            </div>
          ) : (
            <>
              <h3>Wallet Connected</h3>
              <p>Your cryptocurrency wallet is successfully connected</p>
              <TextInput
                type="text"
                value={
                  address.slice(0, 30) +
                  "..." +
                  address?.slice(address.length - 3)
                }
                // onChange={(e) => setIsValue(e.target.value)}
                extraClasses="wallet-input"
              />
              <Button
                variant="primary-contained"
                text="disconnect"
                extraClasses="w-full"
                onClick={() => {
                  disconnect();
                }}
              />
            </>
          )}

          {/* {!isConfirm ? (
            <>
              <h3>{isData?.title}</h3>
              <p>
                Connect your cryptocurrency wallet. If you don’t have one, you
                can create an EVM-compatible wallet. Guild of Heroes will be on
                Avalanche, so make sure your wallet supports it.{" "}
              </p>
              <Button
                variant="primary-contained"
                text="Connect your wallet"
                extraClasses="w-full"
                onClick={() => setIsConfirm(true)}
              />
            </>
          ) : (
            <>
              {!isInput ? (
                <>
                  <h3>{isData?.title}</h3>
                  <p>
                    Connect your cryptocurrency wallet. If you don't have one,
                    create one in your Telegram account
                  </p>

                  <Button
                    variant="primary-contained"
                    text="Check it out"
                    extraClasses="w-full"
                    onClick={() => setIsInput(true)}
                  />
                </>
              ) : (
                <>
                  <h3>Wallet Connected</h3>
                  <p>Your cryptocurrency wallet is successfully connected</p>
                  <TextInput
                    type="text"
                    value={isValue}
                    onChange={(e) => setIsValue(e.target.value)}
                    extraClasses="wallet-input"
                  />
                </>
              )}
            </>
          )} */}
        </div>
      </DModel>
    </>
  );
};

export default DairdropModel;
