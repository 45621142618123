export const baseUrl = process.env.REACT_APP_BASE_URL;

export const prepareHeaders = (headers, { getState }) => {
  const token = getState().auth.token;
  const jwtToken = getState().auth.jwtToken;
  if (token) {
    headers.set("Authorization", `Bearer ${token}`);
    headers.set("jwtauthorization", `Bearer ${jwtToken}`);
  }
  return headers;
};
